import axios from "axios";

export const readTournamentsById = async (tournamentId: any) => {
  try {
    let formData = new FormData();

    formData.append("tournamentId", tournamentId);
    formData.append("token", "token");

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/tournament/read_by_id.php`,
      data: formData,
      headers: {
        Accept: "application/json",
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
