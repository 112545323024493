import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table } from "reactstrap";
import GoalListInMatch from "../../components/goal-list-match/goal-list-match";
import PenaltyListInMatch from "../../components/penalty-list-match/penalty-list-match";

import renderMatchType, {
  renderMatchPenaltyNum,
  renderMatchQuarterNum,
  renderMatchQuarterTime,
} from "../../../meta/renderMatchBlokes";
import { getPlayerListInMatch } from "../../api/matchPlayerApi";
import { readScoreCardById } from "../../api/scoreCardApi";
import LoadingScreen from "../../components/loading-screen/loading-screen";

import MatchScoreCard from "../../components/match-score-card/MatchScoreCard";

import "./score-card-page.css";

function ScoreCardPage() {
  let { scoreCardId } = useParams();
  const [matchPlayerList, setMatchPlayerList] = useState<any>([]);
  const [pending, setPending] = useState(true);
  const [teamOneDataPending, setTeamOneDataPending] = useState(true);
  const [teamTwoDataPending, setTeamTwoDataPending] = useState(true);
  const [matchId, setMatchId] = useState("");
  const [scoreCardItem, setScoreCardItem] = useState<any>({});
  const [currentTab, setCurrentTab] = useState("LIVE");

  useEffect(() => {
    getPlayerListInMatch(matchId).then((dataPlayer) => {
      try {
        if (dataPlayer["result"]) {
          if (dataPlayer["result"] === "SUCCESS") {
            const dataTeamOneToJson = JSON.parse(
              dataPlayer["data"].teamOnePlayers.replace(new RegExp("&quot;", "g"), '"')
            );
            const dataTeamTwoToJson = JSON.parse(
              dataPlayer["data"].teamTwoPlayers.replace(new RegExp("&quot;", "g"), '"')
            );

            setMatchPlayerList({
              matchId: dataPlayer["data"].matchId,
              teamOnePlayers: dataTeamOneToJson,
              teamTwoPlayers: dataTeamTwoToJson,
            });

            if (dataTeamOneToJson) {
              setTeamOneDataPending(false);
            } else {
              setTeamOneDataPending(true);
            }
            if (dataTeamTwoToJson) {
              setTeamTwoDataPending(false);
            } else {
              setTeamTwoDataPending(true);
            }
          } else if (dataPlayer["result"] === "FAILED") {
            setTeamOneDataPending(true);
            setTeamTwoDataPending(true);
          } else {
            setTeamOneDataPending(true);
            setTeamTwoDataPending(true);
          }
        } else {
          setTeamOneDataPending(true);
          setTeamTwoDataPending(true);
        }
      } catch (error) {
        setTeamOneDataPending(true);
        setTeamTwoDataPending(true);
      }
    });
  }, [pending, teamOneDataPending, teamTwoDataPending, matchId]);

  useEffect(() => {
    readScoreCardById(scoreCardId).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setScoreCardItem(data["data"]);

            setMatchId(data["data"].match.matchId);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            window.location.href = `/`;
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [scoreCardId]);

  return (
    <>
      {!pending && (
        <div className="container">
          <div className="row mt-2 mb-2">
            <div className="col-sm-8 score-card-shadow-div">
              <div className="row">
                <div className="col-sm-12">
                  <MatchScoreCard scoreCard={scoreCardItem} />
                </div>
              </div>
              <div className="row pt-1 pb-1">
                <div className="row match-score-btn-div pl-0 m-auto">
                  <table>
                    <thead>
                      <td
                        className={currentTab === "LIVE" ? "tab-btn-active" : ""}
                        onClick={() => setCurrentTab("LIVE")}
                      >
                        Scorecard
                      </td>
                      <td
                        className={currentTab === "COMMENTARY" ? "tab-btn-active" : ""}
                        onClick={() => setCurrentTab("COMMENTARY")}
                      >
                        Commentary
                      </td>
                      <td
                        className={currentTab === "DETAILS" ? "tab-btn-active" : ""}
                        onClick={() => setCurrentTab("DETAILS")}
                      >
                        Details
                      </td>
                      <td
                        className={currentTab === "TEAM" ? "tab-btn-active" : ""}
                        onClick={() => setCurrentTab("TEAM")}
                      >
                        Players
                      </td>
                      <td
                        className={currentTab === "PHOTOS" ? "tab-btn-active" : ""}
                        onClick={() => setCurrentTab("PHOTOS")}
                      >
                        Photos
                      </td>
                    </thead>
                  </table>
                </div>
              </div>
              {currentTab === "LIVE" && (
                <div className="row">
                  <div className="col-sm-12">
                    <GoalListInMatch
                      matchId={scoreCardItem?.match?.matchId}
                      teamOne={scoreCardItem?.match?.teamOne}
                      teamTwo={scoreCardItem?.match?.teamTwo}
                      scoreBoardItem=""
                    />

                    {scoreCardItem?.penaltyStarted === "YES" && (
                      <PenaltyListInMatch
                        matchId={scoreCardItem?.match?.matchId}
                        teamOne={scoreCardItem?.match?.teamOne}
                        teamTwo={scoreCardItem?.match?.teamTwo}
                        scoreBoardItem=""
                      />
                    )}
                  </div>
                </div>
              )}
              {currentTab === "DETAILS" && (
                <>
                  <div className="row pt-4 ">
                    <div className="col-sm-6">
                      <div className="table_outer_div">
                        <Table>
                          <tbody>
                            <tr>
                              <td>
                                <b>Name</b>
                              </td>
                              <td>{scoreCardItem?.match?.name}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Home Team</b>
                              </td>
                              <td>{scoreCardItem?.match?.teamOne?.name}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Away Team</b>
                              </td>
                              <td>{scoreCardItem?.match?.teamTwo?.name}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Location</b>
                              </td>
                              <td>{scoreCardItem?.match?.location}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="table_outer_div">
                        <Table>
                          <tbody>
                            <tr>
                              <td>
                                <b>Tournament</b>
                              </td>
                              <td>{scoreCardItem?.match?.tournament?.name}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Match Scorer</b>
                              </td>
                              <td>{`${scoreCardItem?.match?.scorer?.firstName} ${scoreCardItem?.match?.scorer?.lastName}`}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Match Start at</b>
                              </td>
                              <td>{scoreCardItem?.match?.startTime}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Created</b>
                              </td>
                              <td>{scoreCardItem?.match?.created}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-1 pb-5">
                    <div className="col-sm-12 table-footer">
                      {renderMatchType(scoreCardItem?.match?.type)}
                      {renderMatchQuarterTime(scoreCardItem?.match?.quarterTime)}
                      {renderMatchQuarterNum(scoreCardItem?.match?.numQuarter)}
                      {renderMatchPenaltyNum(scoreCardItem?.match?.numPenalty)}
                    </div>
                  </div>
                </>
              )}
              {currentTab === "TEAM" && (
                <>
                  <div className="row pt-4">
                    <div className="col-sm-6">
                      {!teamOneDataPending && (
                        <div className="table_outer_div">
                          <Table>
                            <thead>
                              <tr>
                                <th>{scoreCardItem?.match?.teamOne?.name}</th>
                              </tr>
                            </thead>
                            <thead>
                              <tr>
                                <th>NUMBER</th>
                                <th>PLAYER</th>
                                <th>ROLE</th>
                              </tr>
                            </thead>
                            <tbody>
                              {matchPlayerList &&
                                matchPlayerList.teamOnePlayers &&
                                matchPlayerList.teamOnePlayers.map(function (player: any, index: any) {
                                  return (
                                    <tr key={index}>
                                      <td>{player?.tNumber}</td>
                                      <td>{player?.userName}</td>
                                      <td>{player?.role}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6">
                      {!teamTwoDataPending && (
                        <div className="table_outer_div">
                          <Table>
                            <thead>
                              <tr>
                                <th>{scoreCardItem?.match?.teamTwo?.name}</th>
                              </tr>
                            </thead>
                            <thead>
                              <tr>
                                <th>NUMBER</th>
                                <th>PLAYER</th>
                                <th>ROLE</th>
                              </tr>
                            </thead>
                            <tbody>
                              {matchPlayerList &&
                                matchPlayerList.teamTwoPlayers &&
                                matchPlayerList.teamTwoPlayers.map(function (player: any, index: any) {
                                  return (
                                    <tr key={index}>
                                      <td>{player?.tNumber}</td>
                                      <td>{player?.userName}</td>
                                      <td>{player?.role}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {pending && <LoadingScreen />}
    </>
  );
}

export default ScoreCardPage;
