import { useEffect } from "react";

import NavBar from "../components/nav-bar/nav-bar";
import TopHeader from "../components/top-header/top-header";

function HomeIndex() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <TopHeader />
      <NavBar />
    </div>
  );
}

export default HomeIndex;
