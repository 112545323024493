import { useEffect, useState } from "react";
import { readMatchesByTournamentIdAndStatus } from "../../api/matchApi";
import MatchFixtureCard from "../match-fixture-card/MatchFixtureCard";
import MatchLiveCard from "../match-live-card/MatchLiveCard";
import MatchResultCard from "../match-result-card/MatchResultCard";

import "./match-schedule.css";

function MatchSchedule({ tournamentId }: any) {
  const [isFixture, setIsFixture] = useState(true);
  const [fixtureMatches, setFixtureMatches] = useState<any>(null);
  const [doneMatches, setDoneMatches] = useState<any>(null);
  const [liveMatches, setLiveMatches] = useState<any>(null);
  const [pending, setPending] = useState(true);

  const triggerTab = (value: any) => {
    setIsFixture(value);
  };

  useEffect(() => {
    readMatchesByTournamentIdAndStatus({
      tournamentId,
      status: "NOT YET",
    }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setFixtureMatches(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });

    readMatchesByTournamentIdAndStatus({
      tournamentId,
      status: "DONE",
    }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setDoneMatches(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });

    readMatchesByTournamentIdAndStatus({
      tournamentId,
      status: "LIVE",
    }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setLiveMatches(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [tournamentId]);
  console.log(tournamentId, fixtureMatches, doneMatches, liveMatches);
  return (
    <>
      {liveMatches?.length !== 0 && (
        <div className="row match-schedule-btn-div">
          <div className="tab-btn-live col-sm-12">Live</div>
        </div>
      )}
      {liveMatches?.length !== 0 && liveMatches && (
        <div className="fixtures-matches">
          <div className="match-schedule-div">
            <div className="row mb-3">
              {liveMatches &&
                liveMatches.map(function (match: any, index: string) {
                  return (
                    <div className="col-sm-6" key={index}>
                      <MatchLiveCard match={match} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}

      <div className="row match-schedule-btn-div">
        <div
          className={isFixture ? "tab-btn col-sm-6  tab-btn-active" : "tab-btn col-sm-6"}
          onClick={() => triggerTab(true)}
        >
          Fixtures
        </div>
        <div
          className={isFixture ? "tab-btn col-sm-6" : "tab-btn col-sm-6  tab-btn-active"}
          onClick={() => triggerTab(false)}
        >
          Results
        </div>
      </div>
      {isFixture && fixtureMatches && (
        <div className="fixtures-matches">
          <div className="match-schedule-div">
            <div className="row mb-5">
              {fixtureMatches &&
                fixtureMatches.map(function (match: any, index: string) {
                  return (
                    <div className="col-sm-6" key={index}>
                      <MatchFixtureCard match={match} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      {!isFixture && doneMatches && (
        <div className="results-matches">
          <div className="match-schedule-div">
            <div className="row">
              {doneMatches &&
                doneMatches.map(function (match: any, index: string) {
                  return (
                    <div className="col-sm-6" key={index}>
                      <MatchResultCard match={match} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MatchSchedule;
