import axios from "axios";

export const readMatchesByStatus = async ({ status }: any) => {
  try {
    let formData = new FormData();

    formData.append("status", status);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/match/read_all.php`,
      data: formData,
      headers: {
        Accept: "application/json",
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readMatchesById = async (matchId: any) => {
  try {
    let formData = new FormData();

    formData.append("matchId", matchId);
    formData.append("token", "token");

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/match/read_by_id.php`,
      data: formData,
      headers: {
        Accept: "application/json",
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readMatchesByTournamentIdAndStatus = async ({ tournamentId, status }: any) => {
  try {
    let formData = new FormData();

    formData.append("tournamentId", tournamentId);
    formData.append("status", status);
    formData.append("token", "token");

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/match/read_by_tournament_id.php`,
      data: formData,
      headers: {
        Accept: "application/json",
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
