import { Suspense } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import HomeIndex from "./pages/home-index";
import HomeFooter from "./components/home-footer/home-footer";
import LoadingScreen from "./components/loading-screen/loading-screen";

import LiveScore from "./pages/score-page/score-page";
import HomePage from "./pages/home-page/page-home";
import ScoreCardPage from "./pages/score-card-page/score-card-page";
import SummaryPage from "./pages/summary-page/summary-page";
import TournamentPage from "./pages/tournament-page/tournament-page";

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HomeIndex />
                <Outlet />
                <HomeFooter />
              </>
            }
          >
            <Route index element={<HomePage />}></Route>
            <Route path="live-scores" element={<LiveScore />}></Route>
            <Route path="score/:scoreCardId" element={<ScoreCardPage />}></Route>
            <Route path="match/:matchId" element={<SummaryPage />}></Route>
            <Route path="tournament/:tournamentId" element={<TournamentPage />}></Route>
          </Route>
          <Route path="*" element={<p>404 PAGE</p>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
