import { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import MediaQuery from "react-responsive";

import "./nav-bar.css";

function NavBar() {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const [pathQuery, setPathQuery] = useState("");
  const [open, setOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location) {
      let path = location.pathname.split("/");
      setPathQuery(path[1]);
    }

    window.addEventListener("scroll", navBarPositionCheck);
    return () => {
      window.removeEventListener("scroll", navBarPositionCheck);
    };
  }, [location]);

  const navBarPositionCheck = () => {
    let elemPosition;
    if (inputRef.current) {
      elemPosition = inputRef.current.getBoundingClientRect();

      if (elemPosition.y > 0) {
        setIsFixed(false);
      } else {
        setIsFixed(true);
      }
    }
  };

  return (
    <>
      <MediaQuery minWidth={993}>
        <div ref={inputRef}>
          <Navbar
            className={isFixed ? "nav-bar-home fixed-top" : "nav-bar-home"}
            collapseOnSelect
            expand="lg"
            variant="dark"
          >
            <Container>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="m-auto">
                  {/* <NavDropdown title="Resorts" id="basic-nav-dropdown">
                <NavDropdown.Item href="1/">Hotel Riu Cancun</NavDropdown.Item>
                <NavDropdown.Item href="2/">Hotel Riu Cancun</NavDropdown.Item>
                <NavDropdown.Item href="3/">Hotel Riu Cancun</NavDropdown.Item>
              </NavDropdown> */}
                  <Nav.Link
                    className={pathQuery === "live-scores" ? "nav-active" : ""}
                    to="live-scores"
                    style={{ margin: "0 13px" }}
                    as={Link}
                  >
                    Live Scores
                  </Nav.Link>
                  <Nav.Link
                    className={pathQuery === "series" ? "nav-active" : ""}
                    to="series"
                    style={{ margin: "0 13px" }}
                    as={Link}
                  >
                    Series
                  </Nav.Link>
                  <Nav.Link
                    className={pathQuery === "teams" ? "nav-active" : ""}
                    to="teams"
                    style={{ margin: "0 13px" }}
                    as={Link}
                  >
                    Teams
                  </Nav.Link>
                  <Nav.Link
                    className={pathQuery === "news" ? "nav-active" : ""}
                    to="news"
                    style={{ margin: "0 13px" }}
                    as={Link}
                  >
                    News
                  </Nav.Link>
                  <Nav.Link
                    className={pathQuery === "stats" ? "nav-active" : ""}
                    to="stats"
                    style={{ margin: "0 13px" }}
                    as={Link}
                  >
                    Stats
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={992}>
        <div ref={inputRef}>
          <Navbar
            className={isFixed ? "nav-bar-home fixed-top" : "nav-bar-home"}
            collapseOnSelect
            expand="lg"
            variant="dark"
          >
            <Container>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setOpen(!open)} />
              <Navbar.Collapse id="responsive-navbar-nav" in={open}>
                <Nav className="m-auto" onClick={() => setOpen(!open)}>
                  {/* <NavDropdown title="Resorts" id="basic-nav-dropdown">
                <NavDropdown.Item href="1/">Hotel Riu Cancun</NavDropdown.Item>
                <NavDropdown.Item href="2/">Hotel Riu Cancun</NavDropdown.Item>
                <NavDropdown.Item href="3/">Hotel Riu Cancun</NavDropdown.Item>
              </NavDropdown> */}
                  <Nav.Link className="live-scores" to="live-scores" style={{ margin: "0 13px" }} as={Link}>
                    Live Scores
                  </Nav.Link>
                  <Nav.Link className="series" to="series" style={{ margin: "0 13px" }} as={Link}>
                    Series
                  </Nav.Link>
                  <Nav.Link className="teams" to="teams" style={{ margin: "0 13px" }} as={Link}>
                    Teams
                  </Nav.Link>
                  <Nav.Link className="news" to="news" style={{ margin: "0 13px" }} as={Link}>
                    News
                  </Nav.Link>
                  <Nav.Link className="stats" to="stats" style={{ margin: "0 13px" }} as={Link}>
                    Stats
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </MediaQuery>
    </>
  );
}

export default NavBar;
