import moment from "moment";

import "./MatchFixtureCard.css";

function MatchFixtureCard({ match, matchPage = true }: any) {
  return (
    <div className="match-card">
      <h5>{moment(match?.startTime).format("MMMM Do YYYY, h:mm:ss a")}</h5>
      <p>
        <a href={`/match/${match.matchId}`} className="a-tab-border-bottom">
          {match?.name}
        </a>
        , {match?.location}, {moment(match?.startTime).format("MMM Do YYYY")},{" "}
        <a href={`/tournament/${match.tournament.tournamentId}/`} className="a-tab-border-bottom">
          {match?.tournament?.name}
        </a>
      </p>
      <div className="team-div">
        <img src="https://via.placeholder.com/10x15" alt="team-img"></img>
        <a href={`/team/${match?.teamOne?.path}`}>
          <h4>{match?.teamOne?.name}</h4>
        </a>
      </div>
      <div className="team-div">
        <img src="https://via.placeholder.com/10x15" alt="team-img"></img>
        <a href={`/team/${match?.teamTwo?.path}`}>
          <h4>{match?.teamTwo?.name}</h4>
        </a>
      </div>
      <p className="match-yet">Match yet to begin</p>
      {matchPage && (
        <div className="match-btn-section">
          <a className="match-summary" href={`/match/${match.matchId}`}>
            Summary
          </a>
          <a className="match-summary" href={`/tournament/${match.tournament.tournamentId}/`}>
            Series Home
          </a>
        </div>
      )}
    </div>
  );
}

export default MatchFixtureCard;
