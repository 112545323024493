import { AiFillTrophy } from "react-icons/ai";
import moment from "moment";
import { useEffect, useState } from "react";
import { millisToMinutesAndSeconds } from "../../../meta/other";

import "./MatchScoreCard.css";

function MatchScoreCard({ scoreCard }: any) {
  let [teamOneNumP, setTeamOneNumP] = useState(0);
  let [teamTwoNumP, setTeamTwoNumP] = useState(0);

  useEffect(() => {
    if (scoreCard?.teamOnePenalties) {
      let tempTeamOneNumP = 0;
      (scoreCard?.teamOnePenalties).split("").forEach((character: string) => {
        if (character === "S") {
          tempTeamOneNumP = tempTeamOneNumP + 1;
          setTeamOneNumP(tempTeamOneNumP);
        }
      });
    }

    if (scoreCard?.teamTwoPenalties) {
      let tempTeamTwoNumP = 0;
      (scoreCard?.teamTwoPenalties).split("").forEach((character: string) => {
        if (character === "S") {
          tempTeamTwoNumP = tempTeamTwoNumP + 1;
          setTeamTwoNumP(tempTeamTwoNumP);
        }
      });
    }
  }, []);
  console.log(scoreCard);
  console.log(scoreCard?.match?.status);
  return (
    <div className="match-score-card">
      {scoreCard?.match?.status === "LIVE" && (
        <>
          <span className="ui red label">LIVE</span>
          <span className="ui yellow label">QUARTER {scoreCard?.currentQuarter}</span>
          <span className="ui blue label">{millisToMinutesAndSeconds(scoreCard?.currentQuarterTime)}</span>
        </>
      )}

      <p>
        <a href={`/match/${scoreCard?.match.matchId}`} className="a-tab-border-bottom">
          {scoreCard?.match?.name}
        </a>
        , {scoreCard?.match?.location}, {moment(scoreCard?.match?.startTime).format("MMM Do YYYY")},{" "}
        <a href={`/tournament/${scoreCard?.match.tournament?.tournamentId}/`} className="a-tab-border-bottom">
          {scoreCard?.match?.tournament?.name}
        </a>
      </p>
      <div className="team-div">
        <img src="https://via.placeholder.com/10x15" alt="team-img"></img>
        <a href={`/team/${scoreCard?.match?.teamOne?.path}`}>
          <h3>{scoreCard?.match?.teamOne?.name}</h3>
        </a>
        {scoreCard?.match?.teamOne?.teamId === scoreCard?.match?.winner?.teamId && <AiFillTrophy size={20} />}
        <span className="ui green label">G - {scoreCard?.teamOneGoals}</span>
        {scoreCard?.penaltyStarted === "YES" && <span className="ui orange label">P - {teamOneNumP}</span>}
      </div>
      <div className="team-div">
        <img src="https://via.placeholder.com/10x15" alt="team-img"></img>
        <a href={`/team/${scoreCard?.match?.teamTwo?.path}`}>
          <h3>{scoreCard?.match?.teamTwo?.name}</h3>
        </a>
        {scoreCard?.match?.teamTwo?.teamId === scoreCard?.match?.winner?.teamId && <AiFillTrophy size={20} />}
        <span className="ui green label">G - {scoreCard?.teamTwoGoals}</span>
        {scoreCard?.penaltyStarted === "YES" && <span className="ui orange label">P - {teamTwoNumP}</span>}
      </div>
      {scoreCard?.match?.status === "LIVE" && <p className="match-yet">Ongoing Match</p>}
      <p className="match-yet">
        {scoreCard?.match?.winner === "DRAW" ? "Match is tied" : scoreCard?.match?.winner?.name} won the match
      </p>
    </div>
  );
}

export default MatchScoreCard;
