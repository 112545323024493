import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { getPlayerListInMatch } from "../../api/matchPlayerApi";
import { readPenaltiesInMatchByTeam } from "../../api/penaltyApi";
import { getPlayerById, renderPenaltyPlayer } from "../../../meta/other";

import "./penalty-list-match.css";

interface props {
  matchId: string;
  teamOne: any;
  teamTwo: any;
  scoreBoardItem: any;
}

function PenaltyListInMatch({ matchId, teamOne, teamTwo, scoreBoardItem }: props) {
  const [teamOnePenaltyPending, setTeamOnePenaltyPending] = useState(true);
  const [teamTwoPenaltyPending, setTeamTwoPenaltyPending] = useState(true);
  const [teamOnePenalties, setTeamOnePenalties] = useState([]);
  const [teamTwoPenalties, setTeamTwoPenalties] = useState([]);

  const [teamOnePlayerList, setTeamOnePlayerList] = useState([]);
  const [teamTwoPlayerList, setTeamTwoPlayerList] = useState([]);
  const [teamOneDataPending, setTeamOneDataPending] = useState(true);
  const [teamTwoDataPending, setTeamTwoDataPending] = useState(true);

  useEffect(() => {
    getPlayerListInMatch(matchId).then((dataPlayer) => {
      try {
        if (dataPlayer["result"]) {
          if (dataPlayer["result"] === "SUCCESS") {
            const dataTeamOneToJson = JSON.parse(
              dataPlayer["data"].teamOnePlayers.replace(new RegExp("&quot;", "g"), '"')
            );
            const dataTeamTwoToJson = JSON.parse(
              dataPlayer["data"].teamTwoPlayers.replace(new RegExp("&quot;", "g"), '"')
            );
            setTeamOnePlayerList(dataTeamOneToJson);
            setTeamTwoPlayerList(dataTeamTwoToJson);
          } else if (dataPlayer["result"] === "FAILED") {
            setTeamOneDataPending(true);
            setTeamTwoDataPending(true);
          } else {
            setTeamOneDataPending(true);
            setTeamTwoDataPending(true);
          }
        } else {
          setTeamOneDataPending(true);
          setTeamTwoDataPending(true);
        }
      } catch (error) {
        setTeamOneDataPending(true);
        setTeamTwoDataPending(true);
      }
    });
  }, [teamOneDataPending, teamTwoDataPending, matchId]);

  useEffect(() => {
    let teamOneId = teamOne?.teamId;
    let teamTwoId = teamTwo?.teamId;

    readPenaltiesInMatchByTeam({ matchId, teamId: teamOneId }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setTeamOnePenalties(data["data"]);
            setTeamOnePenaltyPending(false);
          } else if (data["result"] === "FAILED") {
            setTeamOnePenaltyPending(true);
          } else {
            setTeamOnePenaltyPending(true);
          }
        } else {
          setTeamOnePenaltyPending(true);
        }
      } catch (error) {
        setTeamOnePenaltyPending(true);
      }
    });
    readPenaltiesInMatchByTeam({ matchId, teamId: teamTwoId }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setTeamTwoPenalties(data["data"]);
            setTeamTwoPenaltyPending(false);
          } else if (data["result"] === "FAILED") {
            setTeamTwoPenaltyPending(true);
          } else {
            setTeamTwoPenaltyPending(true);
          }
        } else {
          setTeamTwoPenaltyPending(true);
        }
      } catch (error) {
        setTeamTwoPenaltyPending(true);
      }
    });
  }, [matchId, teamOne?.teamId, teamTwo?.teamId, scoreBoardItem]);

  return (
    <>
      <div>
        <div className="row d-flex justify-content-center">
          <div className="col pt-2 pb-2">
            <h3 className="mb-0">PENALTIES IN THE MATCH</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 score-left-div">
            {!teamOnePenaltyPending && (
              <div className="table_outer_div_match">
                <div className="col-lg-12 header-txt">
                  <h5>{teamOne?.name}</h5>
                </div>
                <Table responsive>
                  <tbody>
                    {teamOnePenalties.map(function (penalty: any, index: any) {
                      const playerDetails = getPlayerById(teamOnePlayerList, penalty?.player)[0];
                      return (
                        <tr>
                          <td key={index}>
                            {renderPenaltyPlayer(playerDetails?.tNumber, playerDetails?.userName, penalty?.status)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <div className="col-sm-6 score-left-div">
            {!teamTwoPenaltyPending && (
              <div className="table_outer_div_match">
                <div className="col-lg-12 header-txt">
                  <h5>{teamTwo?.name}</h5>
                </div>
                <Table responsive>
                  <tbody>
                    {teamTwoPenalties.map(function (penalty: any, index) {
                      const playerDetails = getPlayerById(teamTwoPlayerList, penalty?.player)[0];
                      return (
                        <tr key={index}>
                          <td>
                            {renderPenaltyPlayer(playerDetails?.tNumber, playerDetails?.userName, penalty?.status)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PenaltyListInMatch;
