import { useEffect, useState } from "react";
import { readMatchesByStatus } from "../../api/matchApi";

import MatchLiveCard from "../../components/match-live-card/MatchLiveCard";

function HomePage() {
  const [liveMatches, setLiveMatches] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    readMatchesByStatus({
      tournamentId: 1,
      status: "LIVE",
    }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setLiveMatches(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, []);

  return (
    <div className="live-score-div">
      <div className="container">
        <div className="row pt-5 pb-5">
          <div className="col-sm-12">
            {liveMatches?.length !== 0 && liveMatches && (
              <div className="fixtures-matches">
                <div className="match-schedule-div">
                  <div className="row mb-3">
                    {liveMatches &&
                      liveMatches.map(function (match, index) {
                        return (
                          <div className="col-sm-4" key={index}>
                            <MatchLiveCard match={match} />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
