import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table } from "reactstrap";
import { readMatchesById } from "../../api/matchApi";
import { getPlayerListInMatch } from "../../api/matchPlayerApi";
import renderMatchType, {
  renderMatchPenaltyNum,
  renderMatchQuarterNum,
  renderMatchQuarterTime,
} from "../../../meta/renderMatchBlokes";
import LoadingScreen from "../../components/loading-screen/loading-screen";
import MatchFixtureCard from "../../components/match-fixture-card/MatchFixtureCard";

import "./summary-page.css";

function SummaryPage() {
  let { matchId } = useParams();
  const [match, setMatch] = useState<any>([]);
  const [matchPlayerList, setMatchPlayerList] = useState<any>([]);
  const [pending, setPending] = useState(true);
  const [teamOneDataPending, setTeamOneDataPending] = useState(true);
  const [teamTwoDataPending, setTeamTwoDataPending] = useState(true);

  const [currentTab, setCurrentTab] = useState("SUMMARY");

  useEffect(() => {
    readMatchesById(matchId).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setMatch(data["data"]);
            setPending(false);

            getPlayerListInMatch(matchId).then((dataPlayer) => {
              try {
                if (dataPlayer["result"]) {
                  if (dataPlayer["result"] === "SUCCESS") {
                    const dataTeamOneToJson = JSON.parse(
                      dataPlayer["data"].teamOnePlayers.replace(new RegExp("&quot;", "g"), '"')
                    );
                    const dataTeamTwoToJson = JSON.parse(
                      dataPlayer["data"].teamTwoPlayers.replace(new RegExp("&quot;", "g"), '"')
                    );

                    setMatchPlayerList({
                      matchId: dataPlayer["data"].matchId,
                      teamOnePlayers: dataTeamOneToJson,
                      teamTwoPlayers: dataTeamTwoToJson,
                    });

                    if (dataTeamOneToJson) {
                      setTeamOneDataPending(false);
                    } else {
                      setTeamOneDataPending(true);
                    }
                    if (dataTeamTwoToJson) {
                      setTeamTwoDataPending(false);
                    } else {
                      setTeamTwoDataPending(true);
                    }
                  } else if (dataPlayer["result"] === "FAILED") {
                    setTeamOneDataPending(true);
                    setTeamTwoDataPending(true);
                  } else {
                    setTeamOneDataPending(true);
                    setTeamTwoDataPending(true);
                  }
                } else {
                  setTeamOneDataPending(true);
                  setTeamTwoDataPending(true);
                }
              } catch (error) {
                setTeamOneDataPending(true);
                setTeamTwoDataPending(true);
              }
            });
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [pending, teamOneDataPending, teamTwoDataPending, matchId, match?.type]);

  return (
    <>
      {!pending && (
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-sm-8 summary-card-shadow-div">
              <div className="row">
                <div className="col-sm-12">
                  <MatchFixtureCard match={match} matchPage={false} />
                </div>
              </div>
              <div className="row pt-1 pb-1">
                <div className="row match-score-btn-div pl-0 m-auto">
                  <table>
                    <thead>
                      <td
                        className={currentTab === "SUMMARY" ? "tab-btn-active" : ""}
                        onClick={() => setCurrentTab("SUMMARY")}
                      >
                        Summary
                      </td>
                      <td
                        className={currentTab === "STATISTICS" ? "tab-btn-active" : ""}
                        onClick={() => setCurrentTab("STATISTICS")}
                      >
                        Statistics
                      </td>
                      <td
                        className={currentTab === "TEAM" ? "tab-btn-active" : ""}
                        onClick={() => setCurrentTab("TEAM")}
                      >
                        Players
                      </td>
                    </thead>
                  </table>
                </div>
              </div>
              {currentTab === "SUMMARY" && (
                <>
                  <div className="row pt-4 ">
                    <div className="col-sm-6">
                      <div className="table_outer_div">
                        <Table>
                          <tbody>
                            <tr>
                              <td>
                                <b>Name</b>
                              </td>
                              <td>{match?.name}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Home Team</b>
                              </td>
                              <td>{match?.teamOne?.name}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Away Team</b>
                              </td>
                              <td>{match?.teamTwo?.name}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Location</b>
                              </td>
                              <td>{match?.location}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="table_outer_div">
                        <Table>
                          <tbody>
                            <tr>
                              <td>
                                <b>Tournament</b>
                              </td>
                              <td>{match?.tournament?.name}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Match Scorer</b>
                              </td>
                              <td>{`${match?.scorer?.firstName} ${match?.scorer?.lastName}`}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Match Start at</b>
                              </td>
                              <td>{match?.startTime}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Created</b>
                              </td>
                              <td>{match?.created}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-1 pb-5">
                    <div className="col-sm-12 table-footer">
                      {renderMatchType(match?.type)}
                      {renderMatchQuarterTime(match?.quarterTime)}
                      {renderMatchQuarterNum(match?.numQuarter)}
                      {renderMatchPenaltyNum(match?.numPenalty)}
                    </div>
                  </div>
                </>
              )}
              {currentTab === "TEAM" && (
                <>
                  <div className="row pt-4">
                    <div className="col-sm-6">
                      {!teamOneDataPending && (
                        <div className="table_outer_div">
                          <Table>
                            <thead>
                              <tr>
                                <th>{match?.teamOne?.name}</th>
                              </tr>
                            </thead>
                            <thead>
                              <tr>
                                <th>NUMBER</th>
                                <th>PLAYER</th>
                                <th>ROLE</th>
                              </tr>
                            </thead>
                            <tbody>
                              {matchPlayerList &&
                                matchPlayerList.teamOnePlayers &&
                                matchPlayerList.teamOnePlayers.map(function (player: any, index: any) {
                                  return (
                                    <tr key={index}>
                                      <td>{player?.tNumber}</td>
                                      <td>{player?.userName}</td>
                                      <td>{player?.role}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6">
                      {!teamTwoDataPending && (
                        <div className="table_outer_div">
                          <Table>
                            <thead>
                              <tr>
                                <th>{match?.teamTwo?.name}</th>
                              </tr>
                            </thead>
                            <thead>
                              <tr>
                                <th>NUMBER</th>
                                <th>PLAYER</th>
                                <th>ROLE</th>
                              </tr>
                            </thead>
                            <tbody>
                              {matchPlayerList &&
                                matchPlayerList.teamTwoPlayers &&
                                matchPlayerList.teamTwoPlayers.map(function (player: any, index: any) {
                                  return (
                                    <tr key={index}>
                                      <td>{player?.tNumber}</td>
                                      <td>{player?.userName}</td>
                                      <td>{player?.role}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* <div className="row mt-1">
            <div className="col-sm-4">
              <div className="table_outer_div">
                <div className="col-lg-12 header-txt">
                  <h5>Match Details</h5>
                </div>
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Name</b>
                      </td>
                      <td>{match?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Home Team</b>
                      </td>
                      <td>{match?.teamOne?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Away Team</b>
                      </td>
                      <td>{match?.teamTwo?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Location</b>
                      </td>
                      <td>{match?.location}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Tournament</b>
                      </td>
                      <td>{match?.tournament?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Match Scorer</b>
                      </td>
                      <td>{`${match?.scorer?.firstName} ${match?.scorer?.lastName}`}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Responsible</b>
                      </td>
                      <td>{match?.responsible}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Match Start at</b>
                      </td>
                      <td>{match?.startTime}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Created</b>
                      </td>
                      <td>{match?.created}</td>
                    </tr>
                  </tbody>
                </Table>
                <div className="col-lg-12 table-footer">
                  {renderMatchStatus(match?.status)}
                  {renderMatchType(match?.type)}
                  {renderMatchQuarterTime(match?.quarterTime)}
                  {renderMatchQuarterNum(match?.numQuarter)}
                  {renderMatchPenaltyNum(match?.numPenalty)}
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              {!teamOneDataPending && (
                <div className="table_outer_div">
                  <div className="col-lg-12 header-txt">
                    <h5>{`${match?.teamOne?.name} Team`}</h5>
                  </div>
                  <Table>
                    <thead>
                      <tr>
                        <th>NUMBER</th>
                        <th>PLAYER</th>
                        <th>ROLE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matchPlayerList &&
                        matchPlayerList.teamOnePlayers &&
                        matchPlayerList.teamOnePlayers.map(function (player: any, index: string) {
                          return (
                            <tr key={index}>
                              <td>{player?.tNumber}</td>
                              <td>{player?.userName}</td>
                              <td>{player?.role}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
            <div className="col-sm-4">
              {!teamTwoDataPending && (
                <div className="table_outer_div">
                  <div className="col-lg-12 header-txt">
                    <h5>{`${match?.teamTwo?.name} Team`}</h5>
                  </div>
                  <Table>
                    <thead>
                      <tr>
                        <th>NUMBER</th>
                        <th>PLAYER</th>
                        <th>ROLE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matchPlayerList &&
                        matchPlayerList.teamTwoPlayers &&
                        matchPlayerList.teamTwoPlayers.map(function (player: any, index: string) {
                          return (
                            <tr key={index}>
                              <td>{player?.tNumber}</td>
                              <td>{player?.userName}</td>
                              <td>{player?.role}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          </div> */}
        </div>
      )}
      {pending && <LoadingScreen />}
    </>
  );
}

export default SummaryPage;
