import { useEffect, useState } from "react";
import { readMatchesByStatus } from "../../api/matchApi";
import MatchFixtureCard from "../../components/match-fixture-card/MatchFixtureCard";
import MatchLiveCard from "../../components/match-live-card/MatchLiveCard";
import MatchResultCard from "../../components/match-result-card/MatchResultCard";

import "./score-page.css";

function LiveScore() {
  const [fixtureMatches, setFixtureMatches] = useState([]);
  const [doneMatches, setDoneMatches] = useState([]);
  const [liveMatches, setLiveMatches] = useState([]);
  const [pending, setPending] = useState(true);

  const [currentTab, setCurrentTab] = useState("LIVE");

  useEffect(() => {
    readMatchesByStatus({
      status: "NOT YET",
    }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setFixtureMatches(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });

    readMatchesByStatus({
      status: "DONE",
    }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setDoneMatches(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });

    readMatchesByStatus({
      status: "LIVE",
    }).then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setLiveMatches(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, []);

  return (
    <div className="live-score-div">
      <div className="container">
        <div className="row pt-5 pb-5">
          <div className="col-sm-12">
            <div className="row match-schedule-btn-div pl-0">
              <table>
                <thead>
                  <td className={currentTab === "LIVE" ? "tab-btn-active" : ""} onClick={() => setCurrentTab("LIVE")}>
                    Live
                  </td>
                  <td
                    className={currentTab === "UPCOMING" ? "tab-btn-active" : ""}
                    onClick={() => setCurrentTab("UPCOMING")}
                  >
                    Upcoming
                  </td>
                  <td className={currentTab === "DONE" ? "tab-btn-active" : ""} onClick={() => setCurrentTab("DONE")}>
                    Result
                  </td>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            {currentTab === "LIVE" && liveMatches?.length !== 0 && liveMatches && (
              <div className="fixtures-matches">
                <div className="match-schedule-div">
                  <div className="row mb-3">
                    {liveMatches &&
                      liveMatches.map(function (match, index) {
                        return (
                          <div className="col-sm-4" key={index}>
                            <MatchLiveCard match={match} />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            {currentTab === "UPCOMING" && fixtureMatches && (
              <div className="fixtures-matches">
                <div className="match-schedule-div">
                  <div className="row mb-5">
                    {fixtureMatches &&
                      fixtureMatches.map(function (match, index) {
                        return (
                          <div className="col-sm-4" key={index}>
                            <MatchFixtureCard match={match} />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            {currentTab === "DONE" && doneMatches && (
              <div className="results-matches">
                <div className="match-schedule-div">
                  <div className="row mb-5">
                    {doneMatches &&
                      doneMatches.map(function (match, index) {
                        return (
                          <div className="col-sm-4" key={index}>
                            <MatchResultCard match={match} />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveScore;
