import { Container, Navbar } from "react-bootstrap";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";

import "./top-header.css";

function TopHeader() {
  return (
    <>
      <Navbar bg="white">
        <Container>
          <MediaQuery minWidth={1224}>
            <Navbar.Brand to="/" as={Link}>
              <img src="/images/logo/logo.png" height="40" className="d-inline-block align-top" alt="logo" />
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
              {/* <div className="address-div">
                <h5>{hotelMainData?.name}</h5>
                <p>{hotelMainData?.subName}</p>
                <p>{hotelMainData?.subNameDescription}</p>
                <a href={`tel:${currentPhoneNumber}`}>{currentPhoneNumber}</a>
              </div> */}
            </Navbar.Collapse>
          </MediaQuery>
          <MediaQuery maxWidth={1224}>
            <Navbar.Brand to="/" as={Link} style={{ margin: "auto" }}>
              <img src="/images/logo/logo.png" width="100%" className="d-inline-block align-top" alt="hotel logo" />
            </Navbar.Brand>
          </MediaQuery>
        </Container>
      </Navbar>
    </>
  );
}

export default TopHeader;
