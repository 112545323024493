import { Label } from "semantic-ui-react";

export function renderGoalType(value: any) {
  switch (value) {
    case "PENALTY CORNER":
      return <span className="ui green label">PC</span>;
    case "FIELD GOAL":
      return <span className="ui pink label">FG</span>;
    case "PENALTY STROKES":
      return <span className="ui gray label">PS</span>;
    default:
      return <span className="ui black label">NM</span>;
  }
}

export function penaltyBoxRender(value: any, index: any) {
  switch (value) {
    case "S":
      return <Label color="green">{index + 1}</Label>;
    case "F":
      return <Label color="red">{index + 1}</Label>;
    default:
      return <Label color="blue">{index + 1}</Label>;
  }
}

export function getPlayerById(object: any, playerId: any) {
  return object.filter((obj: any) => {
    return obj.userId === playerId;
  });
}

export function renderPlayer(tNumber: any, name: any) {
  return (
    <span style={{ minWidth: "220px" }} className="ui violet label">
      <span className="ui white circular label">{tNumber}</span> {name}
    </span>
  );
}

export function millisToMinutesAndSeconds(millis: any) {
  var minutes = Math.floor(millis / 60000);
  var seconds: number = parseInt(((millis % 60000) / 1000).toFixed(0));
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}

export function renderPenaltyPlayer(tNumber: any, name: any, status: any) {
  switch (status) {
    case "SUCCESS":
      return (
        <span style={{ minWidth: "250px" }} className="ui green label">
          <span className="ui white circular label">{tNumber}</span> {name}
        </span>
      );
    case "MISSED":
      return (
        <span style={{ minWidth: "250px" }} className="ui red label">
          <span className="ui white circular label">{tNumber}</span> {name}
        </span>
      );
    default:
      return (
        <span style={{ minWidth: "250px" }} className="ui violet label">
          <span className="ui white circular label">{tNumber}</span> {name}
        </span>
      );
  }
}
