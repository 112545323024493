import { Col, Container, Row } from "react-bootstrap";

import "./home-footer.css";

function HomeFooter() {
  return (
    <div className="home-footer-div pt-3 pb-1">
      <Container>
        <Row className="justify-content-md-center text-center">
          <Col lg="8">
            <ul className="footer-ul-copy footer-ul">
              <li>
                <a href="-">© {new Date().getFullYear()} XYZ Sports Media Ltd. All rights reserved</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeFooter;
