import "./loading-screen.css";

function LoadingScreen() {
  return (
    <div className="loading-screen" id={"loading-screen-identifier"}>
      <div className="img-div">
        <img src="/images/logo/logo.png" alt="img"></img>
      </div>
      <div className="loading-dots">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    </div>
  );
}

export default LoadingScreen;
